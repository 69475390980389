<template>
  <v-app id="inspire" >
    <v-app-bar flat style="min-width: 600px !important; border-bottom: 2px solid #9155fd;">
      <v-container id="MainLayoutContainer" class="mx-auto d-flex align-center justify-center">

        <v-icon v-if="!linkIncludesIndexPage" class="activeClick" @click="toggleDrawer" style="margin-right: 10px;">{{ mdiFormatAlignJustify }}</v-icon>
        <v-icon v-else-if="linkIncludesIndexPage" class="activeClick" style="margin-right: 10px;">{{ mdiFormatAlignJustify }}</v-icon>
        <!--추후에는, 인덱스페이지에서 관리할 항목을 삽입할 예정임. 예를들어, 공지사항, 메모, 문자보내기 등..-->

        <div style="font-size: 12px !important; width: 300px; margin-left: 5px;">
          <v-autocomplete 
            v-model="mainComp"
            label="기관" 
            :items="compNames" 
            variant="underlined" 
            density="compact" 
            hide-details="auto"
            style="max-width: 300px !important; color: #000 !important;"
            class="activeClick longAutoCompleteText"
            clearable
            :prepend-inner-icon="mdiCity"
            auto-select-first
          ></v-autocomplete>
        </div>
        <div style="font-size: 12px !important; width: 170px;">
          <v-autocomplete 
            v-model="mainCompBizGubun"
            label="구분" 
            :items="mainCompBizGubunArr" 
            variant="underlined" 
            density="compact" 
            hide-details="auto"
            style="max-width: 260px !important;"
            class="activeClick"
            :prepend-inner-icon="mdiLabelOutline"
            auto-select-first
            ></v-autocomplete>
        </div>
        <div style="height: 100%; width: auto; padding: 0.5px; margin-left: 20px; border-top: 50px solid #9155FD;"></div>
        <div style="display: flex; align-items: center; margin-left: 10px;">
            <v-icon class="activeClick" @click="decMonth" color="#333">{{ mdiChevronLeft }}</v-icon>
            <v-text-field 
              v-model="mainStartDate"
              type="date" 
              label="시작일" 
              density="compact" 
              variant="outlined"
              hide-details="auto" 
              style="max-width: 150px;"
              ></v-text-field>
          </div>
          <span style="margin: 4px; font-size: 20px;"> ~ </span>
          <div style="display: flex; align-items: center;">

            <v-text-field 
              v-model="mainEndDate"
              type="date" 
              label="종료일" 
              density="compact" 
              variant="outlined"
              hide-details="auto" 
              style="max-width: 150px;"
              ></v-text-field>

            <v-icon class="activeClick" @click="incMonth" color="#333">{{ mdiChevronRight }}</v-icon>
          </div>
          <v-btn :class="{'glamorous-button': true, 'active': btnActive}" @click="mainSearchBtn">적용</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="DateBack">날짜초기화</v-btn>
        <v-btn @click="logout" :append-icon="mdiLogoutVariant">
          Logout
        </v-btn>

      </v-container>
    </v-app-bar>
    <NavigationBar />

    <v-main>
      <v-container>
        <v-row>

          <v-col>

            <slot />
          </v-col>
        </v-row>

      </v-container>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import {
  mdiFormatAlignJustify,
  mdiChevronLeft,
  mdiChevronRight,
  mdiLabelOutline,
  mdiLogoutVariant,
  mdiCity
} from '@mdi/js'
import NavigationBar from './NavigationBar.vue';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import axios from 'axios';
import router from '@/router';
const store = useStore();
const account = ref({
  id: null,
  name: '',
  role: '',
});
const btnActive = ref(false);

const toggleDrawer = () => {
  store.commit('setDrawer', !store.state.drawer);
};


const mainComp = ref(null);
const mainCompBizGubunArr = ref([] as any[]);
const mainCompBizGubun = ref(null);
const compBizGubun = ref([] as any[]);


const changeBizGubun = computed(() => {
  const bizGubunComputed = store.state.mainCompBizGubun;
  return bizGubunComputed; 
});

watch(changeBizGubun, (newValue: any) => {
  if (newValue === null || newValue === '') {
    mainComp.value = null;
    mainCompBizGubun.value = null;
    btnActive.value = false;
  } else if (newValue) {
    mainCompBizGubun.value = newValue;
  }
});

watch(mainCompBizGubun, (newValue: any) => {
  mainCompBizGubun.value = newValue;
  store.commit('setMainCompBizGubun', mainCompBizGubun.value);
});


const changeMainComp = computed(() => {
    const mainCompComputed = store.state.mainComp;
    return mainCompComputed;
});

watch(changeMainComp, (newValue: any) => {
  if (newValue && /\(.*\)/.test(newValue)) {
    mainComp.value = newValue;
  }
});

watch(mainComp, (newValue) => {
  if (newValue && /\(.*\)/.test(newValue)) {
    mainComp.value = newValue
    const matchedbiz = compBizGubun.value.find(biz => biz.includes(newValue));
    if (matchedbiz) {
      const substring = matchedbiz.match(/\(([^)]+)\)\((.*?)\)/)[2]; 
      const services = substring.split('/');
      mainCompBizGubunArr.value = services;
      mainCompBizGubun.value = mainCompBizGubunArr.value[0];
   
    }
  }
  if (newValue === null || newValue === '') {
    mainComp.value = null;
    // indexpage에서 기관 X 했을 때, 리스트 초기화하는 핵심 커밋
    mainCompBizGubun.value = null;
    router.push('/indexPage');
    
    btnActive.value = false;
  }
    store.commit('setMainComp', mainComp.value);
    store.commit('setMainCompBizGubun', mainCompBizGubun.value);
});


// 한달을 빼는 함수
function subtractOneMonth(dateString: any) {
  // yyyy-mm 형식의 문자열을 Date 객체로 변환
  const date = new Date(dateString);

  // 현재 월에서 1을 빼고 새로운 Date 객체 생성
  const newDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());

  // 월이 음수가 되면 연도를 조정하고 월을 12로 설정
  if (newDate.getMonth() === -1) {
    newDate.setFullYear(date.getFullYear() - 1);
    newDate.setMonth(11); // 12월로 설정
  }

  // 새로운 날짜를 yyyy-mm 형식의 문자열로 변환하여 반환
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
}
function compareNames(a: any, b: any) {
  // a 또는 b가 undefined일 때 처리
  if (!a || !b || !a.mb_name || !b.mb_name) return 0;

  const nameA = a.mb_name.toUpperCase(); // 대소문자 구분 없이 비교하기 위해 대문자로 변환
  const nameB = b.mb_name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0; // 이름이 같은 경우
}


/* 메인데이트 관련 */
const mainStartDate = ref(''); // 현재 날짜의 yyyy-mm 형식
const mainEndDate = ref(''); // 현재 날짜의 yyyy-mm 형식

const incMonth = () => {
  let startDate = new Date(mainStartDate.value);
  let endDate = new Date(mainEndDate.value);
  // 다음 달 1일로 설정
  startDate.setMonth(startDate.getMonth() + 1, 1);
  // 다음 달의 마지막 날짜로 설정
  endDate.setMonth(endDate.getMonth() + 2, 0);
  mainStartDate.value = startDate.toISOString();
  mainEndDate.value = endDate.toISOString();
};

const decMonth = () => {
  const startDate = new Date(mainStartDate.value);
  const endDate = new Date(mainEndDate.value);
  startDate.setMonth(startDate.getMonth() - 1, 1);
  endDate.setMonth(endDate.getMonth() - 0, 0);
  mainStartDate.value = startDate.toISOString();
  mainEndDate.value = endDate.toISOString();
};


function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

watch(mainStartDate, (newValue: any) => {
  //datepick으로 바꾼 날짜를 감지하여 저장.
  if (newValue > mainEndDate.value){
    mainStartDate.value = mainEndDate.value;
  }else {
    const inputDate = new Date(newValue);
    mainStartDate.value = formatDate(inputDate);
  }
  btnActive.value = true;
});

watch(mainEndDate, (newValue: any) => {
  //datepick으로 바꾼 날짜를 감지하여 저장.
  if (newValue < mainStartDate.value) {
    mainEndDate.value = mainStartDate.value;
  }else {
    const inputDate = new Date(newValue);
    mainEndDate.value = formatDate(inputDate);
  }
  btnActive.value = true;
});

const mainSearchBtn = async () => {
  if (btnActive.value) {
    // btnActive가 true일 때만 동작
    
    store.commit('setMainComp', mainComp.value);
    store.commit('setMainCompBizGubun', mainCompBizGubun.value);
    store.commit('setMainStartDate', mainStartDate.value);
    store.commit('setMainEndDate', mainEndDate.value);
    await getMemberInfo();
  } else {
    // btnActive가 false일 때 새로고침
    location.reload(); // 페이지 새로고침
  }
  btnActive.value = false;
}

function formatStartDate(inputDate: any) {
  const date = new Date(inputDate);
  const newDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
  // 월이 음수가 되면 연도를 조정하고 월을 12로 설정
  if (newDate.getMonth() === -1) {
      newDate.setFullYear(date.getFullYear() - 1);
      newDate.setMonth(11); // 12월로 설정
  }
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}-01`;
}
function formatEndDate(inputDate: any) {
  const date = new Date(inputDate);
  const newDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
  // 월이 음수가 되면 연도를 조정하고 월을 12로 설정
  if (newDate.getMonth() === -1) {
      newDate.setFullYear(date.getFullYear() - 1);
      newDate.setMonth(11); // 12월로 설정
  }
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const daysInMonth = new Date(year, date.getMonth() + 0, 0).getDate();
  return `${year}-${month}-${daysInMonth}`;
}

const DateBack = () => {
  mainStartDate.value = formatStartDate(new Date().toISOString()); 
  mainEndDate.value = formatEndDate(new Date().toISOString()); 
}



const currentPath = ref(''); 
const linkIncludesMain = ref(false);
const linkIncludesIndexPage = ref(false);

// comp선택 or 셀렉트 코드 끝
const compNames = ref([] as any[]);
const compNames2 = ref([] as any[]);

const getMemberInfo = async () => {
  try {
    const response = await axios.get(`/api/getMemReportInfo/${subtractOneMonth(store.state.mainStartDate)}`);
    const responseData = response.data;
    if (Array.isArray(responseData)) {
      compNames2.value = responseData.filter((item, index, arr) => {
        return arr.findIndex(i => i.memid === item.memid) === index;
      });
      compNames.value = compNames2.value.map(item => `${item.mb_name}(${item.memid})`);
      compNames.value.sort(compareNames);

  
      compBizGubun.value = compNames2.value.map(item => `${item.mb_name}(${item.memid})(${item.bizGubun})`)
      // compScode.value = compNames2.value.map(item => item.scode);
      //현재는 메인 기관 선택창이 전월 출력완료 목록이지만, 미래에는 관리기관 모두 불러온 다음, memreport 테이블과 조인해서 기관scode를 함께 불러올 수 있을듯.
      return responseData;
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

watch(() => router.currentRoute.value.path,
  (newVal) => {
    currentPath.value = newVal.toLowerCase();
    linkIncludesMain.value = currentPath.value.includes('main');
    linkIncludesIndexPage.value = currentPath.value.includes('indexPage');
  }
);



onMounted(async () => {
  mainStartDate.value = store.state.mainStartDate;
  mainEndDate.value = store.state.mainEndDate;
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  await getMemberInfo();
  currentPath.value = router.currentRoute.value.path.toLowerCase();
  linkIncludesMain.value = currentPath.value.includes('main');
  linkIncludesIndexPage.value = currentPath.value.includes('indexPage');

  const matchedbiz = compBizGubun.value.find(biz => biz.includes(mainComp.value));
    if (matchedbiz) {
      const substring = matchedbiz.match(/\(([^)]+)\)\((.*?)\)/)[2]; // 두 번째 괄호 안의 내용 추출
      const services = substring.split('/');
      mainCompBizGubunArr.value = services;
    }
    btnActive.value = false;
});

const logout = () => {
  axios.delete("/api/account").then(() => {
    alert("로그아웃하였습니다.");
    account.value.id = null;
    account.value.name = "";
    account.value.role = "";
    sessionStorage.removeItem('vuex_state');
    sessionStorage.removeItem('crawl_Result_List');
    sessionStorage.removeItem('crawl_Dure_Item');
    sessionStorage.removeItem('mainDateInfo');
    sessionStorage.removeItem('mainCompInfo');
    sessionStorage.removeItem('mainCompBizGubun');
    sessionStorage.removeItem('selected_member');
    sessionStorage.removeItem('mainStartDateInfo');
    sessionStorage.removeItem('mainEndDateInfo');
    sessionStorage.removeItem('inoutDetailList');

    window.location.reload();
  });
};
</script>
<style>
.glamorous-button {
  background-color: #9155fd;
  color: rgb(255, 255, 255) !important;
  font-size: 16px;
  border: none;
  border-radius: 8px; /* 버튼의 둥근 모서리를 조정합니다. */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 효과를 추가합니다. */
  transition: background-color 0.3s, transform 0.2s; /* 애니메이션 효과를 추가합니다. */
}

.glamorous-button:hover {
  background-color: #baaad8 !important; /* 마우스 호버시 배경색 변경 */
  color: rgb(255, 255, 255) !important;

  transform: translateY(-2px); /* 약간 위로 이동하는 애니메이션 효과 */
}

@keyframes sparkle {
  0%, 100% {
    box-shadow: 0 0 5px rgb(255, 11, 11);
  }
  50% {
    box-shadow: 0 0 20px #ff3;
  }
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(1px);
  }
  75% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
  }
}

.glamorous-button.active {
  animation: sparkle 1s infinite, shake 0.5s infinite;
}
</style>