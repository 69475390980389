<template>
  <v-card>
    <div style="display: flex; align-items: center; padding: 6px 0;">
      <h3 style="margin: 0 20px 0 60px; color: red">W4C 매크로</h3>

      <v-text-field class="inoutSearchInput" style="
          max-width:200px; 
          width:auto; 
          min-width:70px;
          margin: 0 20px 0 0;
        " v-model="search" color="#0f7545" label="상대계정검색" single-line clearable :append-inner-icon="mdiMagnify" flat
        hide-details="auto" variant="outlined" density="compact">
      </v-text-field>
      
      <!-- PDF -->
      <div>
        {{ `총 : ${addComma(w4cForm.totalPrice)} 원` }}
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="QueryMaker" text="W4C 매크로 코드생성" class="jogunSelect"></v-btn>
    </div>

    <v-data-table fixed-header density="compact" scrollable :headers="headersArr" :items="filteredItems"
      :search="search" item-value="name" class="itemListTable fontNotoSans400" id="jumunTable" style="
        max-height: calc(100vh - 148px); 
        height: calc(100vh - 148px); 
        overflow-y: hidden; 
        position: relative;
      " :items-per-page="50" :items-per-page-options="[
        { value: 50, title: '50' },
        // { value: 100, title: '100' },
        // { value: 200, title: '200' },
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]" v-if="inoutList.length !== 0">



      <template v-slot:item="{ item, index }">
        <tr>
          <td class="tac">{{ index + 1 }}</td>
          <td class="tac">{{ item.결의일자 || '' }}</td>
          <td class="tac">{{ item.결의구분 || '' }}</td>
          <td class="tac">{{ item.사업 || '' }}</td>
          <td class="tac">{{ item.사업구분 || '' }}</td>
          <td class="tar">{{ addComma(item.금액) || '' }} 원</td>
          <td class="tac">{{ item.계정세목 || '' }}</td>
          <td class="tac">{{ item.상대계정 || '' }}</td>
          <td class="tac">{{ item.자금원천 || '' }}</td>
          <td class="tac">{{ item.적요 || '' }}</td>
        </tr>
      </template>

    </v-data-table>
    <div v-if="isLoading" class="loading-spinner">

    </div>

    <div v-else-if="inoutList.length === 0 && !isLoading" style="
      width: 100%; 
      height: 300px; 
      text-align: center; 
      display: flex; 
      align-items: center; 
      justify-content: center;
    ">
      데이터가 존재하지 않습니다.
    </div>

  </v-card>
  <v-dialog v-model="dialog" >
    <v-card style="width: 1400px; margin: auto;" class="fontNotoSans400">
      <v-card-text>
        <v-row>
          <v-col cols="6">
        
        <h2 class="tac mb-4">w4c 조건선택</h2>
            <v-table class="w4cTb">
              <tbody>
                <tr>
                  <td>
                    기관명/ID
                  </td>
                  <td>
                    <v-text-field v-model="w4cForm.memid" label="기관명/ID" flat hide-details rounded="lg"
                      variant="underlined" style="max-width: 100%;" />
                  </td>
                </tr>
                <tr>
                  <td>
                    사업구분 선택
                  </td>
                  <td>
                    <input type="checkbox" v-model="w4cForm.biz_gubun" value="주야간보호"> 주야간보호
                    <input type="checkbox" v-model="w4cForm.biz_gubun" value="방문요양"> 방문요양
                    <input type="checkbox" v-model="w4cForm.biz_gubun" value="방문목욕"> 방문목욕
                    <input type="checkbox" v-model="w4cForm.biz_gubun" value="공동생활가정"> 공동생활
                    <input type="checkbox" v-model="w4cForm.biz_gubun" value="요양시설"> 요양시설
                    <input type="checkbox" v-model="w4cForm.biz_gubun" value="복지용구용품"> 복지용구용품
                  </td>
                </tr>
                <tr>
                  <td>
                    기간설정
                  </td>
                  <td>
                    <div style="display: flex; ">
                      <v-text-field v-model="w4cForm.startDate" label="시작일" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                      <v-text-field v-model="w4cForm.endDate" label="종료일" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    수입/지출 구분
                  </td>
                  <td>
                    <input type="checkbox" v-model="w4cForm.gubun" value="in"> 수입
                    <input type="checkbox" v-model="w4cForm.gubun" value="out"> 지출
                  </td>
                </tr>
                <tr>
                  <td>
                    사업구분 글자체크(주야간)
                  </td>
                  <td>
                      <v-text-field v-model="w4cForm.ju" label="사업구분: 주야간보호" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                    <div style="display: flex;">

                      <v-text-field v-model="w4cForm.yok" label="사업구분: 방문목욕" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                        <v-text-field v-model="w4cForm.yang" label="사업구분: 방문요양" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                      </div>

                  </td>
                </tr>
                <tr>
                  <td>
                    사업구분 글자체크(요양)
                  </td>
                  <td>
                    
                    <div style="display: flex;">
                      <v-text-field v-model="w4cForm.bojo" label="사업구분: 보조금" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                      <v-text-field v-model="w4cForm.gitabojo" label="사업구분: 기타보조금" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                    </div>
                    <div style="display: flex;">

                      <v-text-field v-model="w4cForm.huwon" label="사업구분: 후원금" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                      <v-text-field v-model="w4cForm.ilban" label="사업구분: 일반사업" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                    </div>

                  </td>
                </tr>
                <tr>
                  <td>
                    가격설정
                  </td>
                  <td>
                      <v-text-field v-model="w4cForm.startPrice" label="시작가격" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                  </td>
                </tr>
                
                <tr>
                  <td>
                    계정세목 포함글자검색
                  </td>
                  <td>
                    <v-text-field v-model="w4cForm.sname" label="계정세목찾기" flat hide-details rounded="lg"
                      variant="underlined" style="max-width: 100%;" />
                  </td>
                </tr>

                <tr>
                  <td>
                    자금원천 글자체크
                  </td>
                  <td>
                    <div style="display: flex;">
                      <v-text-field v-model="w4cForm.su" label="자금원천: 수익사업" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                      <v-text-field v-model="w4cForm.ja" label="자금원천: 자부담" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                    </div>
                    <div style="display: flex;">

                      <v-text-field v-model="w4cForm.hu" label="자금원천: 후원금" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                      <v-text-field v-model="w4cForm.bo" label="자금원천: 보조금" flat hide-details rounded="lg"
                        variant="underlined" style="max-width: 50%;" />
                    </div>

                  </td>
                </tr>

                <tr>
                  <td>
                    자금원천 선택
                  </td>
                  <td>
                    <input type="checkbox" v-model="w4cForm.funding" value="04"> 자부담
                    <input type="checkbox" v-model="w4cForm.funding" value="05"> 후원금
                    <input type="checkbox" v-model="w4cForm.funding" value="06"> 보조금
                    <input type="checkbox" v-model="w4cForm.funding" value="07"> 일반사업
                  </td>
                </tr>
                <tr>
                  <td>
                    적요 포함글자검색
                  </td>
                  <td>
                    <v-text-field v-model="w4cForm.memo" label="적요찾기" flat hide-details rounded="lg"
                      variant="underlined" style="max-width: 100%;" />
                  </td>
                </tr>
              </tbody>

            </v-table>
          </v-col>
          <v-col cols="6">
<pre style="font-size: 10px;">
{{codeComputed}}
</pre>
          </v-col>
        </v-row>
      </v-card-text>
      <v-btn @click="getW4C();" color="black" size="x-large">
        확인
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">


import store from '@/store';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import axios from 'axios';
import {
  mdiMagnify,
} from '@mdi/js'

const dialog = ref(false);
const QueryMaker = () => {
  dialog.value = true;
}
const isLoading = ref(false);


//날짜,기관 변경 감지 함수 시작
const mainStartDate = ref('');
const mainEndDate = ref('');
const mainCompBizGubun = ref();
const mainComp = ref('');

const changeMainComp = computed(() => {
  const mainCompComputed = store.state.mainComp
  return mainCompComputed;
});

watch(changeMainComp, async (newValue: any) => {
  if (newValue) {
    mainComp.value = newValue;
    await getW4C();
  }
});

const changeBizGubun = computed(() => {
  const bizGubunComputed = store.state.mainCompBizGubun
  return bizGubunComputed;
});

watch(changeBizGubun, async (newValue: any) => {
  if (newValue) {
    mainCompBizGubun.value = newValue;
    await getW4C();
  }
});

// 레이아웃 메인데이트 변경 시, 감지
const setMainStartDate = computed(() => {
  const mainStartDateComputed = store.state.mainStartDate
  return mainStartDateComputed;
});

watch(setMainStartDate, async (newValue: any) => {
  if (newValue) {
    mainStartDate.value = newValue;
    await getW4C();
  }
});
const setMainEndDate = computed(() => {
  const mainEndDateComputed = store.state.mainEndDate
  return mainEndDateComputed;
});

watch(setMainEndDate, async (newValue: any) => {
  if (newValue) {
    mainEndDate.value = newValue;
    await getW4C();
  }
});

//날짜,기관 변경 감지 함수 끝

function addComma(number: number) {
  // 숫자가 아니거나 null인 경우 그대로 반환
  if (number === null || isNaN(number)) return number;

  // 숫자를 문자열로 변환 후 컴마 추가
  var strNumber = number.toLocaleString('en-US', { maximumFractionDigits: 2 });

  // 소수점 이하가 모두 0이 아닌 경우 처리
  var decimalIndex = strNumber.indexOf('.');
  if (decimalIndex !== -1) {
    var decimalPart = strNumber.substr(decimalIndex + 1);
    if (decimalPart !== '' && !/^0+$/.test(decimalPart)) {
      // 소수점 이하가 모두 0이 아닌 경우, 소수점 이하를 그대로 유지
      return strNumber;
    }
  }
  // 소수점 이하가 모두 0인 경우 또는 정수인 경우, 소수점 이하를 제거
  return strNumber.split('.')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const headersArr = ref([
  { title: 'No.', key: 'index', sortable: false },
  { title: '결의일자', key: '결의일자', align: 'center', sortable: true },
  { title: '결의구분', key: '결의구분', align: 'center', sortable: true },
  { title: '사업', key: '사업', align: 'center', sortable: true },
  { title: '사업구분', key: '사업구분', align: 'center', sortable: true },
  { title: '금액', key: '금액', align: 'center', sortable: true },
  { title: '계정세목', key: '계정세목', align: 'center', sortable: true },
  { title: '상대계정', key: '상대계정', align: 'center', sortable: true },
  { title: '자금원천', key: '자금원천', align: 'center', sortable: true },
  { title: '적요', key: '적요', align: 'center', sortable: true },
] as any[]);

const search = ref('');

const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return inoutList.value;
  }
  return inoutList.value.filter(item =>
    (item.상대계정 && item.상대계정.toLowerCase().includes(search.value.toLowerCase()))
  );
});

const w4cForm = reactive({
  memid: '',
  startDate : '',
  endDate : '',
  gubun: ['in','out'],
  funding: ['04','05','06','07'],
  startPrice: '-1000000000',
  bojo: '보조금',
  gitabojo: '기타보조금',
  huwon: '후원금',
  ilban: '일반사업',
  memo: '',
  sname: '',
  ju: '주',
  yok: '목',
  yang: '양',
  gu: '구',
  su: '수',
  ja: '자',
  hu: '후',
  bo: '보',
  biz_gubun : ['주야간보호', '방문요양', '방문목욕', '공동생활가정', '요양시설', '복지용구용품'],
  totalPrice : 0,
});


function formatCompId(str: string) {
  const regex = /\(([^)]+)\)/;
  const matches = str.match(regex);
  if (matches && matches.length > 1) {
    return matches[1];
  } else {
    return null; // 괄호 안의 문자열이 없는 경우
  }
}
function formatYear(inputDate: string | number | Date) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  return `${year}`;
}
onMounted(async () => {
  mainStartDate.value = store.state.mainStartDate;
  mainEndDate.value = store.state.mainEndDate;


  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  await getW4C();
})
const code = ref('');
const generateCode = () => {
  return `SELECT
date_format(h.inout_date, '%Y-%m-%d') AS '결의일자',
CASE
  WHEN h.gubun = 'in' THEN '수입'
  WHEN h.gubun = 'out' THEN '지출'
  ELSE ''
END AS '결의구분',
  CASE
    WHEN h.funding = '05' THEN '${w4cForm.huwon}'
    WHEN z.LCODE = '300' AND h.rel_acc = '312' AND z.TRCODE = 'out' THEN '${w4cForm.bojo}'
    WHEN z.LCODE = '100' AND h.rel_acc = '136' AND z.TRCODE = 'out' THEN '${w4cForm.bojo}'
    WHEN h.rel_acc = '413' AND h.scode != '312' and h.memo not LIKE '%(생계%' THEN '${w4cForm.bojo}'
    WHEN h.rel_acc = '413' AND h.scode = '312' THEN '${w4cForm.bojo}'
    when h.memo LIKE '%(생계%' then '${w4cForm.bojo}'
    WHEN z.sname LIKE '%생계비(보조%' then '${w4cForm.bojo}'
    when h.scode = '413' AND h.gubun = 'in' then '${w4cForm.bojo}'
    when h.scode = '136' AND h.rel_acc = '414' then '${w4cForm.gitabojo}'
    when h.scode = '136' AND h.rel_acc = '911' AND h.memo LIKE '%보조%' then '${w4cForm.gitabojo}'
    WHEN h.memo LIKE '%기타보조%' THEN '${w4cForm.gitabojo}'
    when h.rel_acc = '414' then '${w4cForm.gitabojo}'
    ELSE '${w4cForm.ilban}'
END AS '사업',
  CASE
    WHEN h.biz_gubun = '주야간보호' THEN '${w4cForm.ju}'
    WHEN h.biz_gubun = '방문목욕' THEN '${w4cForm.yok}'
    WHEN h.biz_gubun = '방문요양' THEN '${w4cForm.yang}'
    WHEN h.biz_gubun = '복지용구용품' THEN '${w4cForm.gu}'
  END AS '사업구분',
h.price AS '금액',
  z.sname AS '계정세목',
(
SELECT 
    sname 
  FROM 
    mysql.zwiz_commoncode 
  WHERE 
    scode = h.rel_acc
  AND 
    h.status = 'Y'
  AND 
    TRCODE = 'in'
  AND
    year = '${formatYear(w4cForm.startDate)}'  
  AND 
    h.memid = '${formatCompId(w4cForm.memid)}'
      ) AS '상대계정',
CASE
    WHEN h.funding = '06' THEN '${w4cForm.su}'
    WHEN h.funding = '04' THEN '${w4cForm.ja}'
    WHEN h.funding = '05' THEN '${w4cForm.hu}'
    WHEN h.funding = '07' THEN '${w4cForm.bo}'
    ELSE '일반사업'
END AS '자금원천',
h.memo AS '적요'
                                      
FROM
  zerowiz.hp_inout AS h
left JOIN mysql.zwiz_commoncode AS z
  ON h.scode = z.SCODE
  AND h.gubun = z.trcode
  AND h.status = 'Y'
  AND z.year = '${formatYear(w4cForm.startDate)}'
  AND (h.scode_nm is null OR h.scode_nm not LIKE '%후원금%' OR h.scode_nm <> '')
WHERE
  z.SNAME IS NOT NULL
  AND spt_kind != 'goods'
  AND h.inout_date >= '${w4cForm.startDate}'
  AND h.inout_date <= '${w4cForm.endDate}'
  AND h.memid = '${formatCompId(w4cForm.memid)}'
  AND (h.gubun = '${w4cForm.gubun[0] || ''}' OR h.gubun = '${w4cForm.gubun[1] || ''}') 
  AND (h.funding = '${w4cForm.funding[0] || ''}' OR h.funding = '${w4cForm.funding[1] || ''}' OR h.funding = '${w4cForm.funding[2] || ''}' OR h.funding = '${w4cForm.funding[3] || ''}') 
  AND h.memo LIKE '%${w4cForm.memo}%'
  AND z.sname LIKE '%${w4cForm.sname}%'
  AND (h.biz_gubun = '${w4cForm.biz_gubun[0] || ''}' OR h.biz_gubun = '${w4cForm.biz_gubun[1] || ''}' OR h.biz_gubun = '${w4cForm.biz_gubun[2] || ''}' OR h.biz_gubun = '${w4cForm.biz_gubun[3] || ''}'  OR h.biz_gubun = '${w4cForm.biz_gubun[4] || ''}' OR h.biz_gubun = '${w4cForm.biz_gubun[5] || ''}') 
  AND h.price >= '${w4cForm.startPrice}'
  ORDER BY h.price;`
};
const codeComputed = computed(() => generateCode());
watch(w4cForm, () => {
    code.value = generateCode();
}, { deep: true });

const inoutList = ref([] as any[]);
const getW4C = async () => {
  isLoading.value = true;
  try {
    w4cForm.startDate = store.state.mainStartDate;
    w4cForm.endDate = store.state.mainEndDate;
    w4cForm.memid = store.state.mainComp;
    const code = encodeURIComponent(codeComputed.value)
    const response = await axios.get(`/api/getW4C/${code}`,);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      inoutList.value = responseData
      w4cForm.totalPrice = calculateTotalPrice();
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    isLoading.value = false;
    dialog.value= false;
  }
};
const calculateTotalPrice = () => {
    // inoutList.value가 배열인지 확인합니다.
    if (Array.isArray(inoutList.value)) {
        // 배열의 각 요소에서 '가격' 속성을 추출하고 합산합니다.
        const totalPrice = inoutList.value.reduce((total, item) => {
            // 가격 속성이 숫자인지 확인합니다.
            const price = (item.금액); // 가격 속성을 숫자로 변환합니다.
            return total + (isNaN(price) ? 0 : price); // 유효한 숫자일 경우만 합산합니다.
        }, 0);

        return totalPrice;
    } else {
        console.error('inoutList.value는 배열이 아닙니다.');
        return 0;
    }
};
</script>
<style>
.w4cTb tr td{
  min-height: 50px !important;
  padding: 8px 16px;
  border: 1px solid #d1d1d1 !important;
}
</style>