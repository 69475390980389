// store/index.ts

import { createStore } from 'vuex';
import CryptoJS from 'crypto-js';

// 암호화 키 (반드시 안전한 곳에 보관되어야 함)
const ENCRYPTION_KEY = 'stepUASDFQWERZXCV1234';

const encrypt = (data:any) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  return encryptedData;
};

const decrypt = (encryptedData:any) => {
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

const store = createStore({
  state: {
    role: null,
    name: '',
    drawer: false,
    selectedMember: null, // 추가된 부분: 선택된 멤버 정보
    mainComp: '', // 추가된 부분: 선택된 멤버 정보
    mainCompBizGubun: [], // 추가된 부분: 모든 정보 배열
    mainStartDate: '',
    mainEndDate: '',
    mainYear: '',
    mainBudNum: '',
    mainGubun: '',
    crawlResultList: [], // 추가된 부분: 모든 정보 배열
    crawlDureItem: [], // 추가된 부분: 모든 정보 배열
    inoutDetailList: [], // 추가된 부분: 모든 정보 배열

  },
  mutations: {
    setRole(state, role) {
      state.role = role;
      sessionStorage.setItem('vuex_state', encrypt(state));
    },
    setName(state, name) {
      state.name = name;
      sessionStorage.setItem('vuex_state', encrypt(state));
    },
    setDrawer(state, value) {
      state.drawer = value;
    },
    clearRole(state) {
      state.role = null;
    },
    setSelectedMember(state, member) {
      state.selectedMember = member;
      sessionStorage.setItem('selected_member', encrypt(state));
    },
    setMainComp(state, mainComp) {
      state.mainComp = mainComp;
      sessionStorage.setItem('mainCompInfo', encrypt(state));
    },
    setMainCompBizGubun(state, mainCompBizGubun) {
      state.mainCompBizGubun = mainCompBizGubun;
      sessionStorage.setItem('mainCompBizGubun', encrypt(state));
    },
    setMainStartDate(state, mainStartDate) {
      state.mainStartDate = mainStartDate;
      sessionStorage.setItem('mainStartDateInfo', encrypt(state));
    },
    setMainEndDate(state, mainEndDate) {
      state.mainEndDate = mainEndDate;
      sessionStorage.setItem('mainEndDateInfo', encrypt(state));
    },
    setMainYear(state, mainYear) {
      state.mainYear = mainYear;
      sessionStorage.setItem('mainYearInfo', encrypt(state));
    },
    setMainBudNum(state, mainBudNum) {
      state.mainBudNum = mainBudNum;
      sessionStorage.setItem('mainBudNumInfo', encrypt(state));
    },
    setMainGubun(state, mainGubun) {
      state.mainGubun = mainGubun;
      sessionStorage.setItem('mainGubunInfo', encrypt(state));
    },
    setCrawlResultList(state, crawlResultList) {
      state.crawlResultList = crawlResultList;
      sessionStorage.setItem('crawl_Result_List', encrypt(state));
    },
    setCrawlDureItem(state, crawlDureItem) {
      state.crawlDureItem = crawlDureItem;
      sessionStorage.setItem('crawl_Dure_Item', encrypt(state));
    },

    setInoutDetailList(state, inoutDetailList) {
      state.inoutDetailList = inoutDetailList;
      sessionStorage.setItem('inoutDetailList', encrypt(state));
    },
  },
  actions: {
    login({ commit }, { role, name }) {
      commit('setRole', role);
      commit('setName', name);

    },
    toggleDrawer({ commit, state }) {
      commit('setDrawer', !state.drawer);
    },
    logout({ commit }) {
      commit('clearRole');
    },
    SetMemberInfoDetail({ commit }, member) {
      commit('setSelectedMember', member);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.role,
    isAdmin: (state) => state.role === 'admin',
    name: (state) => state.name,
    getDrawerState: (state) => state.drawer,
    getMemberInfoDetail: (state) => state.selectedMember,
  },
});

// 페이지 로딩 시 sessionStorage에서 저장된 암호화된 상태를 복호화하여 불러옵니다.
const savedState = sessionStorage.getItem('vuex_state');
if (savedState) {``
  store.replaceState(decrypt(savedState));
}
const savedSession = sessionStorage.getItem('selected_member');
if(savedSession){
  store.replaceState(decrypt(savedSession));
}
const savedmainCompInfo = sessionStorage.getItem('mainCompInfo');
if (savedmainCompInfo) {
  store.commit('setMainComp', decrypt(savedmainCompInfo).mainComp);
}
const savedMainCompBizGubun = sessionStorage.getItem('mainCompBizGubun');
if (savedMainCompBizGubun) {
  store.commit('setMainCompBizGubun', decrypt(savedMainCompBizGubun).mainCompBizGubun);
}
const savedMainStartDateInfo = sessionStorage.getItem('mainStartDateInfo');
if (savedMainStartDateInfo) {
  store.commit('setMainStartDate', decrypt(savedMainStartDateInfo).mainStartDate);
}
const savedMainEndDateInfo = sessionStorage.getItem('mainEndDateInfo');
if (savedMainEndDateInfo) {
  store.commit('setMainEndDate', decrypt(savedMainEndDateInfo).mainEndDate);
}
const savedMainYearInfo = sessionStorage.getItem('mainYearInfo');
if (savedMainYearInfo) {
  store.commit('setMainYear', decrypt(savedMainYearInfo).mainYear);
}
const savedMainBudNumInfo = sessionStorage.getItem('mainBudNumInfo');
if (savedMainBudNumInfo) {
  store.commit('setMainBudNum', decrypt(savedMainBudNumInfo).mainBudNum);
}
const savedMainGubunInfo = sessionStorage.getItem('mainGubunInfo');
if (savedMainGubunInfo) {
  store.commit('setMainGubun', decrypt(savedMainGubunInfo).mainGubun);
}
const savedCrawlResultList = sessionStorage.getItem('crawl_Result_List');
if (savedCrawlResultList) {
  store.commit('setCrawlResultList', decrypt(savedCrawlResultList).crawlResultList);
}

const savedCrawlDureItem = sessionStorage.getItem('crawl_Dure_Item');
if (savedCrawlDureItem) {
  store.commit('setCrawlDureItem', decrypt(savedCrawlDureItem).crawlDureItem);
}

const savedInoutDetailList = sessionStorage.getItem('inoutDetailList');
if (savedInoutDetailList) {
  store.commit('setInoutDetailList', decrypt(savedInoutDetailList).inoutDetailList);
}
export default store;
