<template>
  <v-app id="inspire" >
    <v-app-bar flat style="min-width: 600px !important; border-bottom: 2px solid #9155fd;">
      <v-container id="MainLayoutContainer" class="mx-auto d-flex align-center justify-center">

        <v-icon v-if="!linkIncludesIndexPage" class="activeClick" @click="toggleDrawer" style="margin-right: 10px;">{{ mdiFormatAlignJustify }}</v-icon>
        <v-icon v-else-if="linkIncludesIndexPage" class="activeClick" style="margin-right: 10px;">{{ mdiFormatAlignJustify }}</v-icon>
        <!--추후에는, 인덱스페이지에서 관리할 항목을 삽입할 예정임. 예를들어, 공지사항, 메모, 문자보내기 등..-->

        <div style="font-size: 12px !important; width: 300px; margin-left: 5px;">
          <v-autocomplete 
            v-model="mainComp"
            label="기관" 
            :items="compNames" 
            variant="underlined" 
            density="compact" 
            hide-details="auto"
            style="max-width: 300px !important; color: #000 !important;"
            class="activeClick longAutoCompleteText"
            clearable
            :prepend-inner-icon="mdiCity"
            auto-select-first
          ></v-autocomplete>
        </div>
        <div style="font-size: 12px !important; width: 170px;">
          <v-autocomplete 
            v-model="mainCompBizGubun"
            label="사업구분" 
            :items="mainCompBizGubunArr" 
            variant="underlined" 
            density="compact" 
            hide-details="auto"
            style="max-width: 260px !important;"
            class="activeClick"
            :prepend-inner-icon="mdiLabelOutline"
            auto-select-first
            ></v-autocomplete>
        </div>
        <div style="height: 100%; width: auto; padding: 0.5px; margin-left: 20px; border-top: 50px solid #9155FD;"></div>
        <div style="display: flex; align-items: center; margin-left: 10px;">
          <v-icon class="activeClick" @click="decYear" color="#333">{{ mdiChevronLeft }}</v-icon>
            <span style="font-weight: 500; font-size: 16px;">{{ mainYear }} 년</span>
          <v-icon class="activeClick" @click="incYear" color="#333">{{ mdiChevronRight }}</v-icon>
        </div>
        <div style="display: flex; align-items: center; margin-left: 10px;">
          <v-icon 
      class="activeClick" 
      @click="decBudNum" 
      :color="budNum === '본예산' ? '#ccc' : '#333'"
      :disabled="budClassList.length === 0"
    >
      {{ mdiChevronLeft }}
    </v-icon>
    <span style="font-weight: 500; font-size: 16px;">{{ budNum }}</span>
    <v-icon 
      class="activeClick" 
      @click="incBudNum" 
      :color="budClassList.length === 0 || budNum === budClassList[budClassList.length - 1].budNum ? '#ccc' : '#333'"
      :disabled="budClassList.length === 0 || budNum === budClassList[budClassList.length - 1].budNum"
    >
      {{ mdiChevronRight }}
    </v-icon>
    <v-select
      style="margin: 0 20px;"
      v-model="mainGubun"
      :items="gubunValue"
      variant="underlined" 
      hide-details="auto"
      color="indigo"
      label="구분"
      density="compact"
    />
        </div>
        <v-btn :class="{'glamorous-button': true, 'active': btnActive}" @click="mainSearchBtn">적용</v-btn>
        <v-spacer></v-spacer>
        <LogOut/>

      </v-container>
    </v-app-bar>
    <NavigationBar />

    <v-main>
      <v-container>
        <v-row>

          <v-col>

            <slot />
          </v-col>
        </v-row>

      </v-container>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import {
  mdiFormatAlignJustify,
  mdiChevronLeft,
  mdiChevronRight,
  mdiLabelOutline,
  mdiCity
} from '@mdi/js'
import LogOut from './LogOut.vue';
import NavigationBar from './NavigationBar.vue';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import axios from 'axios';
import router from '@/router';
const store = useStore();
const gubunValue= [
  '전체',
  '세입',
  '세출',
]
const btnActive = ref(false);

const toggleDrawer = () => {
  store.commit('setDrawer', !store.state.drawer);
};

const mainCompBizGubunArr = ref([] as any[]);
const compBizGubun = ref([] as any[]);
const budClassList = ref([] as any);

const mainComp = ref('');
const mainCompBizGubun = ref('');
const mainYear = ref(null) as any;
const budNum = ref('') as any;
const mainGubun = ref('전체');

const changeBizGubun = computed(() => {
  const bizGubunComputed = mainCompBizGubun.value;
  return bizGubunComputed; 
});

watch(changeBizGubun, (newValue: any) => {
  if (newValue) {
    mainCompBizGubun.value = newValue;
  }
});

const changeMainComp = computed(() => {
    const mainCompComputed = mainComp.value;
    return mainCompComputed;
});

watch(changeMainComp, (newValue) => {
  if (newValue && /\(.*\)/.test(newValue)) {
    mainComp.value = newValue
    const matchedbiz = compBizGubun.value.find(biz => biz.includes(newValue));
    if (matchedbiz) {
      const substring = matchedbiz.match(/\(([^)]+)\)\((.*?)\)/)[2]; 
      const services = substring.split('/');
      mainCompBizGubunArr.value = services;
      mainCompBizGubun.value = mainCompBizGubunArr.value[0];
    }
  }
  if (newValue === null || newValue === '') {
    mainComp.value = '';
    // indexpage에서 기관 X 했을 때, 리스트 초기화하는 핵심 커밋
    mainCompBizGubun.value = '';
    // router.push('/indexPage');
  }
});
const changeBudNum = computed(() => {
  const budNumComputed = budNum.value;
  return budNumComputed; 
});

watch(changeBudNum, (newValue: any) => {
  if (newValue) {
    budNum.value = newValue;
    if(budNum.value === store.state.mainBudNum &&
      mainComp.value === store.state.mainComp &&
      mainCompBizGubun.value === store.state.mainCompBizGubun &&
      mainYear.value === store.state.mainYear){
        btnActive.value = false;
      }else{
        btnActive.value = true;
    }
  }
});

// comp선택 or 셀렉트 코드 끝
const compNames = ref([] as any[]);
const compNames2 = ref([] as any[]);

const getMemList = async () => {
  try {
    const response = await axios.get(`/api/getMemList/${store.state.mainYear}`);
    const responseData = response.data;
    if (Array.isArray(responseData)) {
      compNames2.value = responseData.filter((item, index, arr) => {
        return arr.findIndex(i => i.memid === item.memid) === index;
      });
      compNames.value = compNames2.value.map(item => `${item.mb_name}(${item.memid})`);
      compNames.value.sort(compareNames);

  
      compBizGubun.value = compNames2.value.map(item => `${item.mb_name}(${item.memid})(${item.bizGubun})`)
      return responseData;
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
function compareNames(a: any, b: any) {
  // a 또는 b가 undefined일 때 처리
  if (!a || !b || !a.mb_name || !b.mb_name) return 0;

  const nameA = a.mb_name.toUpperCase(); // 대소문자 구분 없이 비교하기 위해 대문자로 변환
  const nameB = b.mb_name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0; // 이름이 같은 경우
}


const getOneMemBudClass = async () => {
  try {
    const response = await axios.get(`/api/getOneMemBudClass/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}`);
    const responseData = response.data;
    budClassList.value = responseData;
    if (
      mainComp.value === store.state.mainComp &&
      mainCompBizGubun.value === store.state.mainCompBizGubun &&
      mainYear.value === store.state.mainYear
    ) {
      budNum.value = store.state.mainBudNum;
    }else{

      if (budClassList.value.length === 0) {
        budNum.value = '본예산';  // 배열의 길이가 0일 경우
      } else {
        budNum.value = budClassList.value[budClassList.value.length - 1].budNum;  // 마지막 요소의 budNum 값 설정
      }
    }
    return responseData;
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

const incYear = () => {
  mainYear.value += 1;
};

const decYear = () => {
  mainYear.value -= 1;
};

const decBudNum = () => {
  const index = budClassList.value.findIndex((item: { budNum: any; }) => item.budNum === budNum.value);
  if (index > 0) {
    budNum.value = budClassList.value[index - 1].budNum; // 이전 값으로 변경
  }
};

// budNum 증가 함수
const incBudNum = () => {
  const index = budClassList.value.findIndex((item: { budNum: any; }) => item.budNum === budNum.value);
  if (index < budClassList.value.length - 1) {
    budNum.value = budClassList.value[index + 1].budNum; // 다음 값으로 변경
  }
};

watch([mainComp, mainCompBizGubun, mainYear, mainGubun], async() => {
  await getOneMemBudClass();
  if (
    mainComp.value === store.state.mainComp &&
    mainCompBizGubun.value === store.state.mainCompBizGubun &&
    mainYear.value === store.state.mainYear &&
    mainGubun.value === store.state.mainGubun
  ) {
    btnActive.value = false;
  } else {
    btnActive.value = true;
  }
});

const mainSearchBtn = async () => {
  if (btnActive.value) {
    console.log(store.state.mainBudNum,'store.state.mainBudNum')
    // btnActive가 true일 때만 동작
    store.commit('setMainComp', mainComp.value);
    store.commit('setMainCompBizGubun', mainCompBizGubun.value);
    store.commit('setMainYear', mainYear.value);
    store.commit('setMainBudNum', budNum.value);
    store.commit('setMainGubun', mainGubun.value);
  } else {
    // btnActive가 false일 때 새로고침
    location.reload(); // 페이지 새로고침
  }
  btnActive.value = false;
}

const currentPath = ref(''); 
const linkIncludesIndexPage = ref(false);


onMounted(async () => {
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  mainYear.value = store.state.mainYear;
  mainGubun.value = store.state.mainGubun;

  await getMemList();
  await getOneMemBudClass();
  currentPath.value = router.currentRoute.value.path.toLowerCase();
  const matchedbiz = compBizGubun.value.find(biz => biz.includes(mainComp.value));
    if (matchedbiz) {
      const substring = matchedbiz.match(/\(([^)]+)\)\((.*?)\)/)[2]; // 두 번째 괄호 안의 내용 추출
      const services = substring.split('/');
      mainCompBizGubunArr.value = services;
    }
    btnActive.value = false;
});

</script>
<style>
.glamorous-button {
  background-color: #9155fd;
  color: rgb(255, 255, 255) !important;
  font-size: 16px;
  border: none;
  border-radius: 8px; /* 버튼의 둥근 모서리를 조정합니다. */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 효과를 추가합니다. */
  transition: background-color 0.3s, transform 0.2s; /* 애니메이션 효과를 추가합니다. */
}

.glamorous-button:hover {
  background-color: #baaad8 !important; /* 마우스 호버시 배경색 변경 */
  color: rgb(255, 255, 255) !important;

  transform: translateY(-2px); /* 약간 위로 이동하는 애니메이션 효과 */
}

@keyframes sparkle {
  0%, 100% {
    box-shadow: 0 0 5px rgb(255, 11, 11);
  }
  50% {
    box-shadow: 0 0 20px #ff3;
  }
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(1px);
  }
  75% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
  }
}

.glamorous-button.active {
  animation: sparkle 1s infinite, shake 0.5s infinite;
}
</style>