<template>
   <div style="padding: 0 16px 16px 16px">
      <v-btn class="jogunSelect" style="color: #333;">본예산작성</v-btn>
      <v-btn class="jogunSelect" style="color: #333;">추가예산작성</v-btn>
      <v-btn class="jogunSelect" style="color: #333;">예산삭제</v-btn>
      <v-btn class="jogunSelect" style="color: #333;">전년도예산복사</v-btn>
      <v-btn class="jogunSelect" style="color: #333; float: right;">엑셀저장</v-btn>
      <v-btn class="jogunSelect" style="color: #333; float: right;">상세보기</v-btn>
    </div>
  <v-card style="min-height: 80vh">
   
    <table class="budListTable">
      <thead>
        <tr>
          <th>구분</th>
          <th>관</th>
          <th>항</th>
          <th>목</th>
          <th>보조금</th>
          <th>자부담</th>
          <th>후원금</th>
          <th>수익사업</th>
          <th>예산총액</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="mainGubun === '전체' || mainGubun === '세입'">
          <tr
            class="budListTr"
            v-for="(item, index) in budInListWithRowspan"
            :key="index"
          >
            <!-- 구분 -->
            <td v-if="item.rowspan.구분" :rowspan="item.rowspan.구분" class="tac">
              {{ item.구분 }}
            </td>
            <!-- 관 -->
            <td v-if="item.rowspan.관" :rowspan="item.rowspan.관" class="tac">
              {{ item.관 }}
            </td>
            <!-- 항 -->
            <td v-if="item.rowspan.항" :rowspan="item.rowspan.항" class="tac">
              {{ item.항 }}
            </td>
            <!-- 나머지 항목들은 그대로 출력 -->
            <td class="tac">{{ item.목 }}</td>
            <td class="tar">{{ item.보조금 }}</td>
            <td class="tar">{{ item.자부담 }}</td>
            <td class="tar">{{ item.후원금 }}</td>
            <td class="tar">{{ item.수익사업 }}</td>
            <td class="tar">{{ item.예산총액 }}</td>
          </tr>
          <tr class="budSumListTr" v-if="budSumList">
            <td class="tac" colspan="4">합계</td>
            <td class="tar">{{ budSumList.in_보조금_총합 || "" }}</td>
            <td class="tar">{{ budSumList.in_자부담_총합 || "" }}</td>
            <td class="tar">{{ budSumList.in_후원금_총합 || "" }}</td>
            <td class="tar">{{ budSumList.in_수익사업_총합 || "" }}</td>
            <td class="tar">{{ budSumList.in_예산총액_총합 || "" }}</td>
          </tr>
        </template>
        <template v-if="mainGubun === '전체' || mainGubun === '세출'">
          <tr
            class="budListTr"
            v-for="(item, index) in budOutListWithRowspan"
            :key="index"
          >
            <!-- 구분 -->
            <td v-if="item.rowspan.구분" :rowspan="item.rowspan.구분" class="tac">
              {{ item.구분 }}
            </td>
            <!-- 관 -->
            <td v-if="item.rowspan.관" :rowspan="item.rowspan.관" class="tac">
              {{ item.관 }}
            </td>
            <!-- 항 -->
            <td v-if="item.rowspan.항" :rowspan="item.rowspan.항" class="tac">
              {{ item.항 }}
            </td>
            <!-- 나머지 항목들은 그대로 출력 -->
            <td class="tac">{{ item.목 }}</td>
            <td class="tar">{{ item.보조금 }}</td>
            <td class="tar">{{ item.자부담 }}</td>
            <td class="tar">{{ item.후원금 }}</td>
            <td class="tar">{{ item.수익사업 }}</td>
            <td class="tar">{{ item.예산총액 }}</td>
          </tr>
          <tr class="budSumListTr" v-if="budSumList">
            <td class="tac" colspan="4">합계</td>
            <td class="tar">{{ budSumList.out_보조금_총합 || "" }}</td>
            <td class="tar">{{ budSumList.out_자부담_총합 || "" }}</td>
            <td class="tar">{{ budSumList.out_후원금_총합 || "" }}</td>
            <td class="tar">{{ budSumList.out_수익사업_총합 || "" }}</td>
            <td class="tar">{{ budSumList.out_예산총액_총합 || "" }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </v-card>
</template>
<script setup lang="ts">
import store from "@/store";
import { onMounted, ref, watch } from "vue";
import axios from "axios";

const mainComp = ref(null) as any;
const mainCompBizGubun = ref(null) as any;
const mainYear = ref(null) as any;
const budNum = ref(null) as any;
const mainGubun = ref(null) as any;

const budList = ref([] as any[]);
const budOutList = ref([] as any[]);
const budInList = ref([] as any[]);
const budSumList = ref();


const budInListWithRowspan = ref([] as any[]);
const budOutListWithRowspan = ref([] as any[]);
const calculateRowspan = (list: any[]) => {
  const updatedList = list.map((item: any) => ({
    ...item,
    rowspan: { 구분: 1, 관: 1, 항: 1, 목: 1 },
  }));
  let 구분Rowspan = 1,
    관Rowspan = 1,
    항Rowspan = 1;

  for (let i = 0; i < updatedList.length; i++) {
    const current = updatedList[i];
    const prev = updatedList[i - 1];

    if (prev && prev.구분 === current.구분) {
      구분Rowspan++;
      current.rowspan.구분 = 0;
    } else {
      if (i > 0) updatedList[i - 구분Rowspan].rowspan.구분 = 구분Rowspan;
      구분Rowspan = 1;
    }

    if (prev && prev.관 === current.관 && prev.구분 === current.구분) {
      관Rowspan++;
      current.rowspan.관 = 0;
    } else {
      if (i > 0) updatedList[i - 관Rowspan].rowspan.관 = 관Rowspan;
      관Rowspan = 1;
    }

    if (
      prev &&
      prev.항 === current.항 &&
      prev.관 === current.관 &&
      prev.구분 === current.구분
    ) {
      항Rowspan++;
      current.rowspan.항 = 0;
    } else {
      if (i > 0) updatedList[i - 항Rowspan].rowspan.항 = 항Rowspan;
      항Rowspan = 1;
    }
  }

  // 마지막 그룹에 대해 rowspan 설정
  if (updatedList.length > 0) {
    updatedList[updatedList.length - 구분Rowspan].rowspan.구분 = 구분Rowspan;
    updatedList[updatedList.length - 관Rowspan].rowspan.관 = 관Rowspan;
    updatedList[updatedList.length - 항Rowspan].rowspan.항 = 항Rowspan;
  }

  return updatedList;
};

const getBudgetList = async () => {
  try {
    const response = await axios.get(
      `/api/getBudgetList/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`
    );
    const responseData = response.data;
    budSumList.value = responseData.sum;
    budList.value = responseData.list;
    budOutList.value = budList.value.filter(
      (item: { 구분: string }) => item.구분 === "세출"
    );
    budInList.value = budList.value.filter(
      (item: { 구분: string }) => item.구분 === "세입"
    );

    // budInList의 rowspan 계산
    budInListWithRowspan.value = calculateRowspan(budInList.value);
    budOutListWithRowspan.value = calculateRowspan(budOutList.value);
    return responseData;
  } catch (error) {
    console.error("errorMsg:", error);
  }
};

watch(
  () => [
    store.state.mainComp,
    store.state.mainCompBizGubun,
    store.state.mainYear,
    store.state.mainBudNum,
    store.state.mainGubun,
  ],
  async ([newMainComp, newMainCompBizGubun, newMainYear, newBudNum, newMainGubun]) => {
    mainComp.value = newMainComp;
    mainCompBizGubun.value = newMainCompBizGubun;
    mainYear.value = newMainYear;
    budNum.value = newBudNum;
    mainGubun.value = newMainGubun
    await getBudgetList();
  }
);
onMounted(async () => {
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  mainYear.value = store.state.mainYear;
  budNum.value = store.state.mainBudNum;
  mainGubun.value = store.state.mainGubun;
  await getBudgetList();
});
</script>
<style>
.budListTable {
  width: 100%;
  font-size: 18px;
  border-collapse: collapse;
  border: 1px solid #fff;
}
.budListTable th {
  font-size: 15px;
  padding: 15px 5px;
  background-color: rgb(196, 208, 255);
  border: 1px solid #b4b4b4;
  border-collapse: collapse;
  color: #282828;
}
.budListTable td {
  border: 1px solid #b4b4b4;
  border-collapse: collapse;
  padding: 10px 5px;
  color: #282828;
}
.budListTable .budListTr {
  font-size: 13px;
}
.budSumListTr {
  background-color: rgb(235, 239, 255);
  color: #282828;
}
.budListTable .budSumListTr td {
  font-size: 14px;
  padding: 6px 5px;
  font-weight: 500;
}
.budListTable tbody .budListTr:hover {
  background-color: #ececec;
}
.budListTable thead tr th:nth-child(1) {
  width: 3%;
}
.budListTable thead tr th:nth-child(2) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(3) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(4) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(5) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(6) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(7) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(8) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(9) {
  width: 12.125%;
}
</style>
